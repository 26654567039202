<template>
  <div class="history">
    <top-header/>
    <div class="panel">
      <div class="panel__left">
        <search-bar @search="search" :placeHolderText="$t('global.search_history')" />
        <div class="panel__content">
          <div class="panel__map">
            <search-map viewOnly :item="history" type="histories"/>
          </div>
          <details-page :item="history" type="history"/>
        </div>
        <bottom-menu/>
      </div>
      <div class="panel__right">
      </div>
    </div>
    <loading v-if="isLoading"/>
  </div>
</template>

<script>
import Loading from '@/components/loading/Loading'
import SearchMap from '@/components/map/map'
import BottomMenu from '@/components/bottomMenu/bottomMenu'
import SearchBar from '@/components/searchBar/searchBar'
import TopHeader from '@/components/header/header'
import DetailsPage from '@/components/detailsPage/detailsPage'
import { mapGetters } from 'vuex'
import { Histories } from '@/api/api'
export default {
  name: 'history',
  components: { Loading, TopHeader, SearchMap, SearchBar, BottomMenu, DetailsPage },
  data () {
    return {
      isLoading: true,
      loadingStatus: 0,
      history: null,
      filters: {}
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed'
    })
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      this.isLoading = true

      const id = this.$route.params?.id

      try {
        const resp = await Histories.get(id)

        this.history = resp.data
        console.log(this.history)
      } catch (e) {
        this.$toast.error(this.$t('histories.load_failed2'))
      } finally {
        this.isLoading = false
      }
    },
    search (data) {
      for (const key in data.filters) {
        if (!data.filters[key]) {
          delete data.filters[key]
        }
      }

      if (!data.filters.searchTerm && !data.filters.districts && !data.filters.tagId && !data.filters.forms && !data.filters.nationalEducation) {
        return
      }

      const params = new URLSearchParams(data.filters).toString()
      const url = `/histories?${params}`

      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "style";
</style>
